<template>
  <div class="users">
    <breadcrumbs :breadcrumbsData="breadcrumbsData" >
      <!-- <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-wode2"></use>
      </svg> -->
    </breadcrumbs>
    <div class="filters">
      <div class="search">
        <div class="option">
          <span>Email</span> 
          <artmisads-input 
          v-model:value="filters.email" 
          @press-enter="getPartData({})"
          placeholder="Enter Email"
          ></artmisads-input>
        </div>
        <div class="option" >
          <span>Username</span> 
          <a-auto-complete
            v-model:value="filters.sellerName"
            :options="options"
            style="width: 200px"
            size="large"
            @search="onSearch"
            @select="getPartData({})"
            allow-clear
            @clear="onReset"
            placeholder="Enter Username"
            :getPopupContainer="
                  triggerNode => {
                    return triggerNode.parentNode || document.body;
                  }
                "
          >
          </a-auto-complete>

        </div>
        <div class="option">
          <span>Brand</span> 
          <artmisads-input 
          v-model:value="filters.brandName" 
          @press-enter="getPartData({})"
          placeholder="Enter brand"
          ></artmisads-input>
        </div>
      </div>
      <div class="button">
        <artmisads-button isSquare="true" type="primary"
        style="margin-right: 8px;"
          @click="getPartData({})"
          >Search</artmisads-button
        >
        <artmisads-button isSquare="true" @click="onReset">Reset</artmisads-button>
      </div>
    </div>
    <div class="export">
      <artmisads-button isSquare="true" @click="exportData">Export</artmisads-button>
    </div>
    <div class="table-data">
      <artmisads-table
        v-model:pagination="pagination"
        :columns="columns"
        :data-source="data"
        @change="onTableChange"
        :loading="load"
        
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key === 'createTime'">
            <div>
              {{
                moment(parseInt(record.createTime)).format(
                  "YYYY-MM-DD"
                )
              }}
            </div>
            <div>
              {{
                moment(parseInt(record.createTime)).format(
                  "HH:mm:ss"
                )
              }}
            </div>
          </template>
          <template v-if="column.key === 'url'">
            <div class="show-url">{{ record.url }}</div>
          </template>
        </template>
      </artmisads-table>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, toRaw, onMounted } from "vue";
import { operatorSellerApi } from "~/server/index";
import breadcrumbs from "../breadcrumbs.vue";
import { SmileOutlined, DownOutlined } from "@ant-design/icons-vue";
import moment from "moment";
const breadcrumbsData = {
  title: "Sellers",
  des: "",
  // color: "#FFD358",
  // hasTitleIcon: true,
  links: [
    {
      name: "Home",
      path: "/#/operation/users",
    },
    {
      name: "Sellers",
      path: "/#/operation/seller",
    },
  ],
};
const columns = [
  {
    title: "Time",
    key: "createTime",
    dataIndex: "createTime",
    width: 120,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Username",
    dataIndex: "sellerName",
    key: "sellerName",
    width:120
  },
  {
    title: "Brand",
    dataIndex: "brandName",
    key: "brandName",
    width:120
  },
  {
    title: "Advertiser Name",
    dataIndex: "advertiserName",
    key: "advertiserName",
    width: 200,
  },
  {
    title: "URL",
    key: "url",
    dataIndex: "url",
  },
];
let filters=reactive({
  email:'',
  sellerName:'',
  brandName:''
});
let data = ref([]);
let _data=[];
let load =ref(true);
let options=ref([])
const _options=[];
const pagination = reactive({
  current: 1,
  defaultPageSize: 200,
  size: 200,
  pageSize: 200,
  page: 1,
  total: 50,
  pageSizeOptions:['20','50','100','200'],
  showSizeChanger: true,
});
const onTableChange = ({ current, pageSize }) => {
  pagination.current = current;
  pagination.size = pageSize;
  pagination.pageSize = pageSize;
  getPartData({ page: current, size: pageSize });
};

const init = () => {
  operatorSellerApi.getSellerList({size:200}).then((res) => { 
    data.value = res.data;
    _data=res.data;
    pagination.total = res.total;
    load.value = false;
    res.data.forEach(v=>{
      let isId=_options.find(item=>item.value == v.sellerName);
      if(!isId){
        _options.push({
        value:v.sellerName
        });
      }
     
    })
  });
};
const getPartData = (params) => { 
  load.value = true;
  let _params = {
    page: 1,
    size: pagination.size,
    brandName:filters.brandName,
    email:filters.email,
    sellerName:filters.sellerName
  };
  let newparams = { ..._params, ...params };
  newparams = Object.keys(newparams).reduce((acc, key) => {
    if (
      newparams[key] !== null &&
      newparams[key] !== undefined &&
      newparams[key] !== ""
    ) {
      acc[key] = newparams[key];
    }
    return acc;
  }, {});
  operatorSellerApi.getSellerList(newparams).then((res) => {
    if (res.data) {
      if (params.size || params.page ) {
        document.querySelector(".filters").scrollIntoView({
          behavior: "smooth",
        });
      }
      
      data.value = res.data;
      pagination.total = res.total;
      load.value = false;
    }
  });
};
const onReset=()=>{
  filters.sellerName='';
  filters.brandName='';
  filters.email='';
  init();
}
const onSearch=e=>{
  options.value=[];
  if(e){
    let res=_options.filter(v=>v.value.toLowerCase().startsWith(e.toLowerCase()))
    options.value=res;
  }
}

const exportData = () => { 
  let params = {
    page: 1,
    size: pagination.size,
    brandName:filters.brandName,
    sellerName:filters.sellerName
  };
  let time=moment().format("YYYY-MM-DD");
  const finalParams = {
    qs:{},
    fileName:`All Sellers_${time}.csv` ,
    platform:'operator'
  };
  operatorSellerApi.sellerExport(finalParams);
};

onMounted(() => {
  init();
});
</script>

<style lang="less" scoped>
.users {
  padding: 16px;
  padding-top: unset;
  width: 100%;
}
.filters {
  display: flex;
  justify-content: space-between;
  .search {
    display: flex;
    .option {
      >span{
        margin-right: 8px;
      }
      display: flex;
      align-items: center;
      margin-right: 24px;
    }
  }
}
.export{
  display: flex;
  justify-content: flex-end;
  margin: 16px 0;
}

.table-data:deep {
  border-radius: 8px;
  border: 1px solid #fff;
  .ant-table {
    border-radius:  8px 8px 0 0;
  }
  .ant-table-thead{
    border-radius: 8px 8px 0 0;
  }
}
.show-url {
  word-break: break-all;
}
</style>
